@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  color: #fff;
}

#root,
.App {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

* {
  box-sizing: border-box;
}

p,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
  cursor: default;
}

input {
  display: flex;
  color: #000;
  background: #fff;
  height: 40px;
  padding: 8px;
  font-size: 14px;
  border-radius: 6px;
  border: 0.5px solid #c4c4c4;
}

button {
  background: #e923f4;
  height: 38px;
  text-align: center;
  padding: 4px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

button:hover {
  background: #cd1fd7;
}

input:focus,
button:focus {
  outline: none;
}
